import { combineReducers } from '@reduxjs/toolkit'

const defaultUserInfo = {
  jwtToken: '',
  readOnlyJwtToken: '',
  id: 0,
  username: '',
  role: 0,
}
const defaultSideBar = {
  sidebarShow: true,
}
const defaultResident = {
  id: '',
  nric_name: '',
  contact_no: '',
  email: '',
  contact_no2: '',
  username: '',
  nric: '',
  is_active: '',
}

const INITIAL_STATE = {
  userInfo: defaultUserInfo,
  sideBar: defaultSideBar,
  residentInfo: defaultResident,
}

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: {
          jwtToken: action.token,
          readOnlyJwtToken: action.readOnlyToken,
          id: action.id,
          username: action.username,
          role: action.role,
        },
      }
    case 'SET_LOGOUT_USER_INFO':
      return {
        ...state,
        userInfo: defaultUserInfo,
      }
    case 'set':
      return {
        ...state,
        sideBar: { ...action },
      }
    case 'setResident':
      return {
        ...state,
        residentInfo: {
          id: action.id,
          nric_name: action.nric_name,
          email: action.email,
          contact_no: action.contact_no,
          contact_no2: action.contact_no_2,
          nric: action.nric,
          is_active: action.is_active,
          username: action.username,
        },
      }
    default:
      return state
  }
}

export default combineReducers({
  app: reducers,
})
